// @ts-nocheck
// import $ from 'jquery';
import { makeStyles, Grid } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react'
import Layout from '../layout';
import Location from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from './controls/RoundButton';
// import { TextInput } from './Textinput';
// import Textinputtime from '../components/inputtime';
import Select from './select';
import Textarea from './textarea';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { confirm_STATELESS, modifyInfo, modifyInfo_STATELESS } from '../store/actions/appactions';
// import moment from 'moment';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByAddress } from 'react-google-places-autocomplete';
import Textinput from './Textinput';
import { WoraCorbosServiceDeliveriesDeliveryDto, WoraCorbosServiceDeliveryStepsStepType } from '../proxy/Api';
import moment from 'moment';
import { getDeliveryInfo } from '../store/actions/appactions';
import { IConfig, limitations } from '../common/constants/limits-dictionary';
import { store } from '../store/store';

// import { GooglePlacesAutocomplete, geocodeByPlaceId } from 'react-google-places-autocomplete';
// window.jQuery = $;

function DetailScreen(props: any) {

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    icons: {
      display: 'flex',
      alignItems: 'center',
      height: 100,
      justifyContent: 'center'
    },
    iconstwo: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      position: 'relative',
      height: 100
    },
    rounddiv: {
      height: '15px',
      width: '15px',
      borderRadius: '7.5px',
      backgroundColor: 'orange'
    },
    heading: {
      textAlign: 'center',
      color: '#909090',
      fontSize: 14
    },
    headingtwo: {
      fontWeight: 500,
      fontSize: 18,
      maxWidth: '100%',
      textAlign: 'left',
      color: '#000',
    },
    headingthree: {
      color: 'grey',
      fontWeight: 400,
      fontSize: 14,
      marginLeft: 10,
    },
    buttoncontainer: {
      width: '90%',
      paddingTop: 20,
      textAlign: 'center'
    },
    headingsix: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: 0,
      color: '#7D7D7D'
    },
    spantext: {
      fontSize: 15,
      fontWeight: 400,
      maxWidth: '85%',
      textAlign: 'center',
      color: '#909090',
      marginTop: '5px'
    },
    spantexttwo: {
      fontSize: 16,
      marginTop: 20,
      fontWeight: 400,
      color: '#7D7D7D'
    },
    headingone: {
      marginBottom: '-20px'
    },
    icontext: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 800
    },
    iconsocial: {
      fontSize: 12,
      marginRight: 5
    },
    typocontainer: {
      width: '90%'
    },
    inputdiv: {
      paddingTop: 15,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorDiv: {
      width: '80%',
      margin: 'auto',
      padding: '1px 20px'
    },
    label: {
      color: 'grey',
      fontWeight: 400,
      fontSize: 14,
      marginLeft: 10,
    },
    warningmsg: {
      color: 'red'
    },
    warnSpan: {
      color: 'orange',
      textAlign: 'justify'
    }
  }));
  const navstate = useLocation();

  const classes = useStyles();
  let history = useHistory();
  const { token } = useParams<{ [key: string]: string }>();
  const dispatch = useDispatch();
  const app = useSelector((state: any) => state.app)
  const data = useSelector((state: any) => state.app?.userinfo == null ? null : state.app?.userinfo as WoraCorbosServiceDeliveriesDeliveryDto);
  const delivery = data?.steps?.find(elemento => elemento.stepType === WoraCorbosServiceDeliveryStepsStepType.Value1);
  const isProcessing = delivery?.status == 4;
  const isCompletedSuccessfully = delivery?.status == 5;
  const shouldAllowConfirm = (delivery?.windowConfirmed == false && delivery?.changedDateByUser == false) || (delivery?.changedDateByUser == true)
  const time_from = (delivery)
    ? delivery.startEtaWindow
    : null;
  const time_until = (delivery)
    ? delivery.endEtaWindow
    : null;

  const address = app?.userinfo?.to;
  const [citofono, setCitofono] = useState(app?.userinfo?.deliveryInterPhone || '');
  const [indirizzo, setIndirizzo] = useState(address);
  const serviceretrieve = app?.userinfo?.deliveryReception ? { value: 'Si', label: 'Si' } : { value: 'No', label: 'No' };
  const [reception, setReception] = useState(serviceretrieve);
  const [note, setNote] = useState(app?.userinfo?.deliveryNote);
  const [openselect, setOpenselect] = useState(false);
  const [indirizzoError, setIndirizzoError] = useState('');
  const [hasError, setHasError] = useState(false);
  const isInterphoneEmpty = citofono == false

  const [addressRoute, setAddressRoute] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const initialAddressRoute = useRef();
  const initialAddressNumber = useRef();
  const initialZipCode = useRef();
  const initialCity = useRef();
  const initialProvince = useRef();
  const initialLatitude = useRef();
  const initialLongitude = useRef();

  const [initialLoadOk, setInitialLoadOk] = useState(false)//to prevent showing the warn message "if you change address there will be no guarantee of the timespan"

  const isLocationDifferentFromStart =
    (
      (initialAddressRoute.current !== addressRoute)
      || (initialAddressNumber.current != addressNumber && initialAddressNumber.current != undefined)
      || (initialZipCode.current != zipCode)
      || (initialCity.current !== city)
      || (initialProvince.current != province)
      || (initialLatitude.current != latitude)
      || (initialLongitude.current != longitude))


  useEffect(() => {
    console.log("LOCATIONEVENT")
    console.log(initialAddressNumber.current != addressNumber)
    console.log(initialZipCode.current != zipCode)
    console.log(initialCity.current !== city)
    console.log(initialProvince.current != province)
    console.log(initialLatitude.current != latitude)
    console.log(initialLongitude.current != longitude)
    console.log("-----", isLocationDifferentFromStart)

    console.log(addressNumber, zipCode, city, province, latitude, longitude)
    console.log(initialAddressNumber.current, initialZipCode.current, initialCity.current, initialProvince.current, initialLatitude.current, initialLongitude.current)
    if (isLocationDifferentFromStart)
      console.log("DIFFERENT!")

  }, [isLocationDifferentFromStart, initialLoadOk])
  useEffect(() => {
    //if the driver is driving to deliver this delivery, then redirect to tracking
    if (isProcessing == true)
      history.push("/tracking/" + token);
    if (isCompletedSuccessfully == true)
      history.push('/completed/' + token);
  }, [isProcessing, isCompletedSuccessfully])

  const [storeSettings, setStoreSettings] = useState<IConfig | undefined>(undefined)

  const [autoCompleteTrigger, setAutoCompleteTrigger] = useState(false)

  function unFocusAutocomplete() {
    document.getElementById("indirizzoInput")?.blur()
  }

  useEffect(() => {
    if (token !== undefined) {
      dispatch(getDeliveryInfo(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (openselect) {
      // event = document.createEvent('MouseEvents');
      // event.initMouseEvent('mousedown', true, true, window);
      // const dropdown = document.getElementById('Citifono');
      // dropdown?.dispatchEvent(event);
    }
  }, [openselect])

  useEffect(() => {
    setNote(app?.userinfo?.deliveryNote || '');
    setIndirizzo(address)
    setReception(serviceretrieve)
    setCitofono(app?.userinfo?.deliveryInterPhone || '')
    setStoreSettings(limitations[app?.userinfo?.storeId])

    if (address) {
      geocodeByAddress(address).then((results: any) => {
        setIndirizzo(results[0].formatted_address);
        validateAddress(results[0], true);
      }
      )
        .catch((error: any) => {
          console.error(error)
          setIndirizzoError(error);
          setHasError(true);
        }
        );
    }

  }, [app?.userinfo]);

  useEffect(() => {
    if (autoCompleteTrigger) {
      setAutoCompleteTrigger(false)

      if (city && city != storeSettings?.CITY_LIMIT && !storeSettings?.CAPS_LIMIT.includes(parseInt(zipCode))) {
        setIndirizzoError("CAP non servito")
        setHasError(true)
      }
      else {
        setHasError(false)
      }
    }
  }, [city, autoCompleteTrigger])//The google autocomplete component is horrible, we needed a manual trigger
  const onSubmit = (e: any) => {
    e.preventDefault();
    let portineria = serviceretrieve.value === 'No' ? false : true;    //TODO: Check correct endpoint ! modifyInfo is alright, 
    const hasDateChangedByUser = navstate.state?.datehaschanged
    const tf = time_from
    const tt = time_until

    console.log("HASDATECHANGED: ", hasDateChangedByUser)

    modifyInfo_STATELESS(
      delivery!.contactId!.toString(),
      token,
      addressRoute,
      addressNumber,
      zipCode,
      city,
      province,
      note,
      latitude,
      longitude,
      citofono,
      portineria,
      isLocationDifferentFromStart
    ).then(x => {
      if (x == 0) {
        //SUCCESS INFO MODIFICATION
        if (hasDateChangedByUser) {
          //No need to explicitly call the confirm ... we are done
          history.push("/thankyouscreen/" + token, { changetype: "alsoTime" });
        }
        else {
          //If there wasnt a changedate then we must confirm the timespan given to us (ETA)
          confirm_STATELESS(token, tf, tt).then(c => {
            if (c == 0) {
              history.push("/thankyouscreen/" + token, { changetype: "locationOnly" });
            }
            else {
              history.push("/err")
            }
          })
        }
      }
      else {
        history.push("/err")
      }
    })
  }

  const options = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
  ]

  const handelChangeCitofono = (e: any) => {
    setCitofono(e.target.value);
  }
  const handleAddress = (data: any, InputAction: any) => {

    if (InputAction && (InputAction.action === 'select-option' || InputAction.action === 'input-change')) {
      if (InputAction.action === 'select-option') {
        geocodeByPlaceId(data.value.place_id)
          .then((results: any) => {
            setIndirizzo(results[0].formatted_address);
            validateAddress(results[0]);
          }
          )
          .catch((error: any) => {
            console.error(error)
            setIndirizzoError(error);
            setHasError(true);
          }
          );
        unFocusAutocomplete()
      }
      else {
        setIndirizzo(data)
        setIndirizzoError('Selezionare dalla lista');
        setHasError(true);
      }
    }
  }

  const goConfirm = () => {
    history.push({
      pathname: '/confirm/' + token,
    });
  }
  const validateAddress = (value: any, isInitialState = false) => {
    setHasError(false);

    setLatitude(value.geometry.location.lat());
    setLongitude(value.geometry.location.lng());

    if (isInitialState) {
      initialLatitude.current = value.geometry.location.lat()
      initialLongitude.current = value.geometry.location.lng()
    }
    value.address_components.some((item: any) => {
      if (item.types && Array.isArray(item.types)) {
        if (item.types.includes('postal_code')) {
          setZipCode(item.short_name);
        }
        if (item.types.includes('route')) {
          setAddressRoute(item.short_name);
        }
        if (item.types.includes('administrative_area_level_2')) {
          setProvince(item.short_name);
        }
        if (item.types.includes('locality')) {
          setCity(item.short_name);
        }
        if (item.types.includes('street_number')) {
          setAddressNumber(item.short_name);
        }

        if (isInitialState) {
          if (item.types.includes('postal_code')) {
            initialZipCode.current = (item.short_name);
          }
          if (item.types.includes('route')) {
            initialAddressRoute.current = (item.short_name);
          }
          if (item.types.includes('administrative_area_level_2')) {
            initialProvince.current = (item.short_name);
          }
          if (item.types.includes('locality')) {
            initialCity.current = (item.short_name);
          }
          if (item.types.includes('street_number')) {
            initialAddressNumber.current = (item.short_name);
          }
        }
      }
    })
    setAutoCompleteTrigger(true)
    setInitialLoadOk(true)

  }



  return (
    <Layout>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.icons}>
            {navstate.state?.datehaschanged == false && <Location onClick={() => goConfirm()} />
            }
          </Grid>
          <Grid item xs={10} className={classes.iconstwo}>
            <h2 className={classes.headingtwo}>INFORMAZIONI DI CONSEGNA</h2>
          </Grid>
        </Grid>
        {isProcessing && <span className={classes.warningmsg}>La consegna è in corso, non è possibile fare cambiamenti</span>}

        {/* <span className={classes.spantext}>Modificare i dati se non corretti. </span> */}
        {storeSettings?.CAN_CHANGE_ADDRESS && <Box width={'100%'}>
          <div className={classes.inputdiv}>
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              selectProps={{
                inputId: 'indirizzoInput',
                value: { label: indirizzo, value: { description: indirizzo } },
                onInputChange: (v: any, InputAction: any) => {
                  handleAddress(v, InputAction)
                },
                onChange: (v: any, InputAction: any) => {
                  handleAddress(v, InputAction)
                },
                placeholder: indirizzo,
                filterOption: (v: any) => {
                  const secondArgument = parseInt(v.label.split(",")[1]);
                  return v;

                  if (!isNaN(secondArgument)) {
                    // setHasError(false);
                    // setIndirizzoError('');
                  }
                },
                inputValue: indirizzo,
                closeMenuOnSelect: false,
                styles: {
                  container: (provided: any, state: any) => ({
                    ...provided,
                    width: '80%',
                    margin: '-6px auto auto auto',
                    zIndex: state?.isFocused ? 2 : 1,
                    border: indirizzoError ? 'red' : 'none',
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    color: '#9F9F9F'
                  }),
                  control: (provided: any, state: any) => ({
                    ...provided,
                    zIndex: state?.isFocused ? 2 : 1,
                    backgroundColor: '#eeeeee',
                    borderRadius: 50,
                    color: '#9F9F9F',
                    padding: '2px 10px',
                    border: 'none',
                  }),
                },
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['it'],
                }
              }}
            />
          </div>

          {(isLocationDifferentFromStart && initialLoadOk) &&
            <Box className={classes.errorDiv}>
              <span className={classes.warnSpan}>
                In caso di modifica dell'indirizzo, non possiamo garantire la fascia oraria indicata precedentemente.
              </span>
            </Box>
          }
          {hasError &&
            <Box className={classes.errorDiv}>
              <Typography color='error' variant='body2' >{indirizzoError}</Typography>
            </Box>
          }
        </Box>
        }
        <div className={classes.inputdiv}>
          {/* <TextField label='Citofono' id="Citofono" value={citofono} onChange={(e:any) => setCitofono(e.target.value)} /> */}
          {
            Textinput('Citofono', "Citofono", handelChangeCitofono, citofono, isInterphoneEmpty)
          }
        </div>
        <div className={classes.inputdiv}>
          <Select options={options} onClick={() => setOpenselect(!openselect)} label='Servizio di Portineria' id="reception" value={reception} onChange={(value: any) => { setReception(value) }} />
        </div>
        <div className={classes.inputdiv}>
          <Textarea label='Note' id="Citifono" value={note} onChange={(e: any) => setNote(e.target.value)} />
        </div>

        <div className={classes.buttoncontainer}>
          <span style={{ color: 'red', textAlign: 'center' }}>{app?.error?.type === 'updatedata' && app?.error?.data === 'Non puoi modificare questo ordine!' ? app?.error?.data : null}</span>
          {!shouldAllowConfirm && <><span style={{ color: 'red', textAlign: 'center' }}>I dati sono già stati confermati</span><br/></>}
          {isInterphoneEmpty && <><span style={{ color: 'red', textAlign: 'center' }}>Si prega di inserire il citofono</span><br/></>}
          {!storeSettings?.CAN_CHANGE_ADDRESS && hasError && <><span style={{ color: 'red', textAlign: 'center' }}>{indirizzoError}</span><br/></>}
          <Button disabled={hasError || isProcessing || !shouldAllowConfirm || isInterphoneEmpty} text="CONFERMA"
            loading={app.loading} variant="contained" onClick={(e: any) => onSubmit(e)} link={undefined} />
        </div>
      </div>

    </Layout>


  )
}

export default DetailScreen;

